@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css);
:root {
  --bg: #eceaea;
}

body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eceaea;
  background-color: var(--bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


li {
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
  margin-top: 40px;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
}
.App {
  margin: auto;
  max-width: 600px;
  width: 600px;
}

.add-item-input {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  grid-gap: 10px;
}
.add-item-input i {
  display: block;
  line-height: 1em;
}

.list-input {
  background-color:  var(--bg);
}

.shopping-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:  var(--bg);
}

.shopping-list__item--checked {
  opacity: 0.5;
  text-decoration: line-through;
}

.shopping-list__item button {
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
  width: 30px;
  height: 30px;
}

.shopping-list__item-btns {
  display: flex;
  align-items: center;
}

.shopping-list__item button:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.shopping-list__item i.red {
  color: red;
}

.shopping-list__item i.green {
  color: green;
}

@media screen and (max-width: 700px) {
  .App {
    width: calc(100% - 40px);
  }

  .add-item-input {
    margin-top: 12px;
  }
}
