.App {
  margin: auto;
  max-width: 600px;
  width: 600px;
}

.add-item-input {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  grid-gap: 10px;
}
.add-item-input i {
  display: block;
  line-height: 1em;
}

.list-input {
  background-color:  var(--bg);
}

.shopping-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:  var(--bg);
}

.shopping-list__item--checked {
  opacity: 0.5;
  text-decoration: line-through;
}

.shopping-list__item button {
  transition: transform .2s ease;
  width: 30px;
  height: 30px;
}

.shopping-list__item-btns {
  display: flex;
  align-items: center;
}

.shopping-list__item button:hover {
  transform: scale(1.05);
}

.shopping-list__item i.red {
  color: red;
}

.shopping-list__item i.green {
  color: green;
}

@media screen and (max-width: 700px) {
  .App {
    width: calc(100% - 40px);
  }

  .add-item-input {
    margin-top: 12px;
  }
}